import moment from "moment";

import axiosDefault from "../../api";

const GetUsers = async (
  page,
  perPage,
  info,
) => {
  const data = axiosDefault.get(`users`, {
    params: {
      page: page,
      perPage: perPage,
      info,
    },
  });
  return data;
};
const GetUsersFilter = async (
  page,
  perPage,
  info,
  indication,
  isDeath,
  withCategory,
  withoutCategory,
  registeredBy,
  editedBy,
  contactNumber,
  document,
  locationType,
  gender,
  documentType,
  contactNumberType,
  neighborhood,
  needsAddresUpdate,
) => {
  const data = axiosDefault.get(`users`, {
    params: {
      page: page,
      perPage: perPage,
      info: info,
      indication: indication,
      isDeath: isDeath,
      withCategory,
      withoutCategory,
      registeredBy,
      editedBy,
      contactNumber,
      document,
      locationType,
      gender,
      documentType,
      contactNumberType,
      neighborhood,
      needsAddresUpdate,
    },
  });
  return data;
};

const GetFinancesFilter = async (
  page,
  perPage,
  info,
  situation,
  startDate,
  endDate,
) => {
  const data = axiosDefault.get(`finances`, {
    params: {
      page: page,
      perPage: perPage,
      info: info,
      situation: situation,
      startDate: startDate,
      endDate: endDate,
    },
  });
  return data;
};

const GetFinancesCount = async (
  info,
  situation,
  startDate,
  endDate,
) => {
  const data = axiosDefault.get(`finances-count`, {
    params: {
      info: info,
      situation: situation,
      startDate: startDate,
      endDate: endDate,
    },
  });
  return data;
};

const getStatusQuantities = async (params) => {
  const data = axiosDefault.get(`demand/count-of-status`, {
    params: params,
  });
  return data;
}

const GetUsersMapFilter = async (
  category,
  info,
  indication,
) => {
  const data = axiosDefault.get(`map-user`, {
    params: {
      category: category,
      info: info,
      indication: indication,
    },
  });
  return data;
};
const GetDemandsByProperties = async (params) => {
  const data = axiosDefault.get('demand/filter-by-proprietary-id', { 
    params: params
  });
  return data;
}
const GetHistoricFilter = async (
  page,
  perPage,
  situation,
  info,
  indication,
  users,
  responsible,
  categories,
  searchDemand,
  startDate,
  endDate,
  progress_situation,
) => {
  const data = axiosDefault.get(`historic`, {
    params: {
      page: page,
      perPage: perPage,
      situation: situation,
      info: info,
      indication: indication,
      users: users,
      responsible: responsible,
      categories: categories,
      searchDemand: searchDemand,
      startDate: startDate,
      endDate: endDate,
      progress_situation: progress_situation,
    },
  });
  return data;
};
const GetHistoricFilterPdf = async (
  situation,
  info,
  indication,
  users,
  responsible,
  categories,
  searchDemand,
  accountId,
  accountName,
  userAction,
  day,
  hour,
  dateTime,
  startDate,
  progress_situation
) => {
  const data = axiosDefault.get(`historic-pdf`, {
    params: {
      situation: situation,
      info: info,
      indication: indication,
      users: users,
      responsible: responsible,
      categories: categories,
      searchDemand: searchDemand,
      accountId,
      accountName,
      userAction,
      day,
      hour,
      dateTime,
      startDate,
      progress_situation: progress_situation,
    },
  });
  return data;
};
const GetUser = async (id) => {
  const data = axiosDefault.get(`user/${id}`);
  return data;
};
const SearchUser = async (dateOfBirth) => {
  const data = axiosDefault.get(`search-user`, { 
     params: {
      dateOfBirth
     }
  });
  return data;
};
const GetFinances = async (id) => {
  const data = axiosDefault.get(`finances/${id}`);
  return data;
};
const GetBirthDay = async () => {
  const data = axiosDefault.post(`birthday`, {
    dates: [
      moment().add(0, "days").format("MM-DD"),
      moment().add(1, "days").format("MM-DD"),
      moment().add(2, "days").format("MM-DD"),
      moment().add(3, "days").format("MM-DD"),
      moment().add(4, "days").format("MM-DD"),
    ],
    currentDate: moment().format('YYYY-MM-DD')
  });
  return data;
};
const validationDoc = (cpf, rg, titulo) => {
  const data = axiosDefault.post("doc-validation", {
    cpf: cpf,
    rg: rg,
    titulo: titulo,
  });
  return data;
};

const GetHistoricPaginate = (
  id,
  page,
  perPage
) => {
  const data = axiosDefault.get(`/historic-person/paginate-per-person/${id}`, {
    params: {
      page: page,
      limit: perPage,
    },
  });
  return data;
}

const GetDemandsPaginate = (
  id,
  page,
  perPage
) => {
  const data = axiosDefault.get(`/demand/paginate-by-person-id/${id}`, {
    params: {
      page: page,
      limit: perPage,
    },
  });
  return data;
}

const GetHistoryUser = async (
  id,
  page,
  perPage
) => {
  const data = axiosDefault.get(`historic/${id}`, {
    params: {
      page: page,
      perPage: perPage,
    },
  });
  return data;
};

const deleteDemandPerson = async (demandId, payload) => {
  const data = await axiosDefault.post(`demand/delete-by-id/${demandId}`, payload);
  return data;
}

const deleteHistoricPerson = async (id, paylod) => {
  return axiosDefault.post(`delete/historic-person/${id}`, paylod);
}

const deleteHistory = async (
  id,
  accountId,
  accountName,
  day,
  hour,
  userAction,
  userId,
  userName,
  description,
  dateTime
) => {
  const data = axiosDefault.post(`historic/${id}`, {
    accountId,
    accountName,
    day,
    hour,
    userAction,
    userId,
    userName,
    description,
    dateTime,
  });
  return data;
};
const deleteUser = async (id, payload) => {
  const data = axiosDefault.post(`user/${id}`, payload);
  return data;
};

const getPdf = async (
  info,
  indication,
  accountId,
  accountName,
  userAction,
  day,
  hour,
  dateTime,
  isDeath,
  withCategory,
  withoutCategory,
  registeredBy,
  editedBy,
  contactNumber,
  document,
  locationType,
  gender,
  documentType,
  contactNumberType,
  neighborhood,
  needsAddresUpdate,
) => {
  const data = axiosDefault.get(`users-pdf`, {
    params: {
      info,
      indication,
      accountId,
      accountName,
      userAction,
      day,
      hour,
      dateTime,
      isDeath,
      withCategory,
      withoutCategory,
      registeredBy,
      editedBy,
      contactNumber,
      document,
      locationType,
      gender,
      documentType,
      contactNumberType,
      neighborhood,
      needsAddresUpdate,
    },
  });
  return data;
};

const GetDemanById = (demandId) => {
  const data = axiosDefault.get(`demand/find-by-id/${demandId}`);
  return data;
}

const GetRelations = async (id) => {
  const data = axiosDefault.get(`relations/${id}`);
  return data;
};

const GetHistoricByDemand = (id, onlyFiles) => {
  const data = axiosDefault.get(`demand-historic/list-all-by-demand-id/${id}`, {
    params: {
      onlyFiles
    }
  });
  return data;
}

const GetDemands = async (id) => {
  const data = axiosDefault.get(`demandas/${id}`);
  return data;
};
const GetHistorcDetail = async (id) => {
  const data = axiosDefault.get(`historic-detail/${id}`);
  return data;
};
const getStates = async () => {
  const data = axiosDefault.get(`states`);
  return data;
};
const removeRelations = async (
  userId,
  relationId,
  payload
) => {
  const data = axiosDefault.post(`relation/${userId}/${relationId}`, payload);
  return data;
};
const insertRelation = async (
  id,
  relationId,
  relation,
  lastRelation,
  accountId,
  accountName,
  day,
  hour,
  userAction,
  userId,
  userName,
  dateTime,
  description
) => {
  const data = axiosDefault.post(`relation`, {
    userId: id,
    relation: relationId,
    relationName: relation,
    lastRelationName: lastRelation,
    accountId,
    accountName,
    day,
    hour,
    userAction,
    userName,
    dateTime,
    description,
  });
  return data;
};
const saveDataBasicUser = (
  file,
  name,
  dateOfBirth,
  userId,
  phone,
  whatsApp,
  indication,
  category,
  description,
  apelido,
  photo,
  pais,
  city,
  address,
  houseNumber,
  bairro,
  localization,
  rg,
  cpf,
  cnh,
  tituto,
  zona,
  section,
  facebook,
  insta,
  occupation,
  state,
  rgOrgao,
  whatsAppContact,
  whatsAppContactName,
  addressReference,
  susNumero,
  email,
  isFace,
  isInst,
  accountName,
  day ,
  hour ,
  userAction ,
  dateTime ,
  phoneContact ,
  phoneContactName ,
  gender,
  countryId,
  stateId,
  cityId,
  neighborhoodId,
  publicPlaceId,
  neighborhoodName,
  publicPlaceName,
  districtId,
) => {
  const formData = new FormData();
  const dataUser = {
    gender,
    name,
    susNumero,
    whatsAppContactName,
    whatsAppContact,
    phone,
    user: userId,
    whatsApp,
    indication,
    dateOfBirth,
    category,
    description,
    apelido,
    photo,
    country: pais,
    city,
    neighborhood: bairro,
    cpf,
    rg,
    cnh,
    street: address,
    occupation,
    zona,
    section,
    socialMidia_facebook: facebook,
    socialMidia_instagran: insta,
    tituto,
    houseNumber,
    localization,
    stateDelete: state,
    rgOrgao,
    addressReference,
    email,
    created: moment().format("DD/MM/YYYY [às] HH:mm:ss"),
    isFace,
    isInst,
    accountId: userId,
    accountName,
    day,
    hour,
    userAction,
    userId: undefined,
    userName: name,
    dateTime,
    phoneContact,
    phoneContactName,
    countryId,
    state: stateId,
    cityId,
    neighborhoodId,
    public_placeId: publicPlaceId,
    neighborhoodName,
    publicPlaceName,
    districtId,
  };
  formData.append("file", file);
  formData.append("data", JSON.stringify(dataUser));
  const data = axiosDefault.post("user", formData);
  return data;
};

const updateDataBasicUser = (
  file,
  id,
  name,
  dateOfBirth,
  phone,
  whatsApp,
  indication,
  category,
  description,
  apelido,
  photo,
  pais,
  city,
  address,
  houseNumber,
  bairro,
  localization,
  rg,
  cpf,
  cnh,
  titulo,
  zona,
  section,
  facebook,
  insta,
  occupation,
  photoId,
  state,
  rgOrgao,
  whatsAppContact,
  whatsAppContactName,
  addressReference,
  susNumero,
  email,
  updatedTo,
  updated,
  isFace,
  isInst,
  accountId,
  accountName,
  day,
  hour,
  userAction,
  userId,
  userName,
  dateTime,
  phoneContact,
  phoneContactName,
  gender,
  isDeath,
  countryId,
  stateId,
  cityId,
  neighborhoodId,
  public_placeId,
  neighborhoodName,
  publicPlaceName,
  districtId,
  addressImageFile,
  photo_of_the_house_key,
  addressImageBase64,
  
) => {
  const formData = new FormData();
  const dataInfo = {
    gender,
    phoneContact,
    phoneContactName,
    name,
    rgOrgao,
    whatsAppContact,
    whatsAppContactName,
    addressReference,
    susNumero,
    phone,
    whatsApp,
    indication,
    dateOfBirth,
    category: category,
    description,
    apelido,
    photo,
    country: pais,
    city,
    neighborhood: bairro,
    cpf,
    rg,
    cnh,
    street: address,
    occupation,
    zona,
    section,
    socialMidia_facebook: facebook,
    socialMidia_instagran: insta,
    titulo,
    houseNumber,
    localization,
    photo_id: photoId,
    active: true,
    stateRemoved: state,
    email,
    updatedTo,
    updated,
    isFace,
    isInst,
    accountId,
    accountName,
    day,
    hour,
    userAction,
    userId,
    userName,
    dateTime,
    isDeath,
    countryId: countryId || null,
    stateId: stateId || null,
    cityId: cityId || null,
    neighborhoodId: neighborhoodId || null,
    public_placeId: public_placeId || null,
    neighborhoodName,
    publicPlaceName,
    districtId: districtId || null,
    photoOfTheHouse: addressImageFile,
    photo_of_the_house_key,
    addressImageBase64,
  };

  formData.append("file", file);
  formData.append("data", JSON.stringify(dataInfo));

  const data = axiosDefault.put(`user/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};

const insertHistoricBirthNotSend = (
  sytemUserId,
  sytemUserName,
  createdByUserAt,
  historicPerson,
  registeredPersonId,
  registeredPersonName,
  birthId,
  userAction,
  ) => {
  const data = axiosDefault.post(`historic-person-birth`, {
    sytemUserId,
    sytemUserName,
    createdByUserAt,
    historicPerson,
    registeredPersonId,
    registeredPersonName,
    birthId,
    accountId: sytemUserId,
    accountName: sytemUserName,
    userAction

  });
  return data;
}

const insertHistoricBirth = async (
  accountId,
  userId,
  day,
  hour,
  description,
  isSolicitation,
  date,
  accountName,
  userName,
  userAction,
  dateTime,
  birth,
  birthId 
) => {
  const data = axiosDefault.post(`historic-birth`, {
    account: accountId,
    user: userId,
    day,
    hour,
    description,
    isSolicitation,
    active: true,
    date,
    accountId,
    accountName,
    userId,
    userName,
    userAction,
    dateTime,
    birth,
    birthId,
  });
  return data;
};

const createHistoricPerson = async (payload) => {
  await axiosDefault.post('historic-person/create', payload)
}

const insertHistoric = async (
  accountId,
  userId,
  day,
  hour,
  description,
  isSolicitation,
  date,
  accountName,
  userName,
  userAction,
  dateTime,
  birth,
  birthId 
) => {
  const data = axiosDefault.post(`historic`, {
    account: accountId,
    user: userId,
    day,
    hour,
    description,
    isSolicitation,
    active: true,
    date,
    accountId,
    accountName,
    userId,
    userName,
    userAction,
    dateTime,
    birth,
    birthId,
  });
  return data;
};

const createDemand = async (payload) => {
  const data = axiosDefault.post(`demand/create`, payload);
  return data;
};

const insertHistoricDemand = async (payload) => {
  const data = axiosDefault.post(`historic`, payload);
  return data;
};

const createHistoricDemand = (formData) => {
  const data = axiosDefault.post(`demand-historic/create`, formData);
  return data;
}

const insertDemand = async (formData) => {
  const data = axiosDefault.post(`demanda`, formData);
  return data;
};

const updateHistoricDemand = (id, payload) => {
  const data = axiosDefault.put(`/demand-historic/update-by-id/${id}`, payload);
  return data;
}

const updateDemand = async (
  account,
  description,
  id,
  updated,
  accountId,
  accountName,
  day,
  hour,
  userAction,
  userId,
  userName,
  dateTime,
  demandName,
  hasFile,
  historicId
) => {
  const data = axiosDefault.put(`demanda/${id}`, {
    updatedTo: account,
    description,
    updated,
    accountId,
    accountName,
    day,
    hour,
    userAction,
    userId,
    userName,
    dateTime,
    descriptionLog: `Editou o histórico da demanda "${demandName}" do cadastrado "${userName}"`,
    hasFile,
    historicId
  });
  return data;
};

const updateDemandUser = async (
  id,
  form
) => {
  const data = axiosDefault.put(`demand/update-by-id/${id}`, form);
  return data;
};

const updateDemandOrigin = async (
  id,
  form
) => {
  const data = axiosDefault.put(`demanda/origin/${id}`, form);
  return data;
};

const saveFinance = async (params) => {
  const data = axiosDefault.post(`finance`, params);
  return data;
};

const deleteFinance = async (id) => {
  const data = axiosDefault.delete(`finance/${id}`);
  return data;
};

const updateFinance = async (
  params,
  id
) => {
  const data = axiosDefault.put(`finance/${id}`, params);
  return data;
};

const updateHistoricPerson = async (userId, payload) => {
  const data = axiosDefault.put(`historic-person/${userId}`, payload);
  return data;
}

const updateHistoric = async (
  userId,
  description,
  updated,
  updatedTo,
  responsible ,
  // -->
  accountId,
  accountName,
  day,
  hour,
  userAction,
  userName,
  dateTime
) => {
  const data = axiosDefault.put(`update/historic/${userId}`, {
    user: userId,
    description,
    updated,
    updatedTo,
    responsible: responsible,
    accountId,
    accountName,
    day,
    hour,
    userAction,
    userName,
    dateTime,
  });
  return data;
};

const getAccountUser = () => {
  const data = axiosDefault.get("users-account");
  return data;
};

const getNeighborhoods = () => {
  const data = axiosDefault.get("place/all-neighborhoods");
  return data;
};

const getCountDemand = () => {
  const data = axiosDefault.get("historic-count");
  return data;
};

const DeleteManyDemand = (id) => {
  const data = axiosDefault.delete(`demanda/${id}`);
  return data;
};

const generatePDF = async (params) => {
  const data = axiosDefault.get("demand/pdf", { 
    params: params,
   });
  return data;
}

const getSituations = () => {
  const data = axiosDefault.get("situations");
  return data;
};

const getSituationsActive = () => {
  const data = axiosDefault.get("situations-active");
  return data;
};

const getAllUsers = () => {
  const data = axiosDefault.get("all-users");
  return data;
};

const updateVisit = (id) => {
  const data = axiosDefault.put(`update-generic/${id}`, {
    isVisit: true,
  });
  return data;
};

const createIndication = (params) => {
  const data = axiosDefault.post(`indication`, params);
  return data;
};

const updateIndication = (id, params) => {
  const data = axiosDefault.put(`indication/${id}`, params);
  return data;
};

const deleteIndication = (id) => {
  const data = axiosDefault.delete(`indication/${id}`);
  return data;
};

const getIndications = (id) => {
  const data = axiosDefault.get(`indications/${id}`);
  return data;
};

const getCountTypesDemand = (
  situation,
  info,
  indication,
  users,
  responsible,
  categories,
  searchDemand,
  startDate,
  endDate,
  progress_situation
) => {
  const data = axiosDefault.get("demand-count", {
    params: {
      situation: situation,
      info: info,
      indication: indication,
      users: users,
      responsible: responsible,
      categories: categories,
      searchDemand: searchDemand,
      startDate: startDate,
      endDate: endDate,
      progress_situation: progress_situation,
    },
  });
  return data;
};

const confirmSendMessage = (
  userId,
  userName,
  accountId,
  accountName,
  date
) => {
  const data = axiosDefault.post(`confirm-send-message`, {
    userId,
    userName,
    accountName,
    accountId,
    date,
    userAction: "Parabéns",
    description: `Mensagem de parabéns para o cadastrado "${userName}" concluído`,
    dateTime: moment(),
    birthdayStatus: moment().format("DD/MM/YYYY")
  });
  return data;
};

const createUserSpeedUserSpeed = async (
  name,
  dateOfBirth,
  category,
  whatsApp,
  phone,
  gender,
  accountId,
  accountName,
  day,
  hour,
  userAction,
  dateTime,
  created,
  description,
  whatsAppContact,
  phoneContact,
  whatsAppContactName,
  phoneContactName,
) => {
  const data = await axiosDefault.post(`/create-user`, {
    name,
    dateOfBirth,
    category,
    whatsApp,
    phone,
    gender,
    accountId,
    accountName,
    day,
    hour,
    userAction,
    dateTime,
    created,
    description,
    whatsAppContact,
    phoneContact,
    whatsAppContactName,
    phoneContactName,
  });
  return data;
}

const updateUserSpeed = async (
  userId,
  userName,
  name,
  dateOfBirth,
  category,
  whatsApp,
  phone,
  gender,
  accountId,
  accountName,
  day,
  hour,
  dateTime,
  description,
  updated,
  whatsAppContact,
  phoneContact,
  whatsAppContactName,
  phoneContactName,
) => {
  await axiosDefault.put(`/update-user/${userId}`, {
    userName,
    name,
    dateOfBirth,
    category,
    whatsApp,
    phone,
    gender,
    accountId,
    accountName,
    day,
    hour,
    dateTime,
    userAction: "Editou",
    updated,
    description,
    whatsAppContact,
    phoneContact,
    whatsAppContactName,
    phoneContactName,
  });
}

const removeSendMessage = (
  userId,
  accountId,
  birthId,
  accountName,
  userName
) => {
  const data = axiosDefault.post(`remove-send-message`, {
    userId,
    birthId,
    accountId,
    userAction: "Parabéns",
    description: `Mensagem de parabéns para o cadastrado "${userName}" desfeito`,
    day: moment().format("L"),
    hour: moment().format("LTS"),
    dateTime: moment(),
    accountName,
    userName,
    createdByUserAt: moment().format("YYYY-MM-DD")
  });
  return data;
};

const GetSituationTypes = async (params) => {
  const data = axiosDefault.get(`demand/count-situation-of-status-id`, {
    params: params,
  });
  return data;
};

const sendFile = (payload) => {
  const data = axiosDefault.post(`upload-file`, payload);
  return data;
};

const getFiles = (id) => {
  const data = axiosDefault.get(`files/${id}`);
  return data;
};

const deleteFiles = (payload) => {
  const data = axiosDefault.post(`/historic-person/delete-file`, payload);
  return data;
};

export default {
  deleteFiles,
  sendFile,
  getFiles,
  confirmSendMessage,
  removeSendMessage,
  GetUsers,
  getCountTypesDemand,
  insertHistoricDemand,
  getAccountUser,
  saveDataBasicUser,
  GetUser,
  GetBirthDay,
  insertRelation,
  GetRelations,
  getStates,
  updateDataBasicUser,
  GetSituationTypes,
  deleteUser,
  getPdf,
  removeRelations,
  deleteHistory,
  insertHistoric,
  GetHistoryUser,
  updateHistoric,
  GetUsersFilter,
  validationDoc,
  getSituations,
  GetDemands,
  getIndications,
  insertDemand,
  GetHistorcDetail,
  DeleteManyDemand,
  updateDemand,
  GetHistoricFilter,
  getCountDemand,
  getAllUsers,
  GetHistoricFilterPdf,
  GetUsersMapFilter,
  saveFinance,
  GetFinances,
  updateFinance,
  GetFinancesFilter,
  GetFinancesCount,
  deleteFinance,
  updateVisit,
  createIndication,
  deleteIndication,
  updateIndication,
  updateDemandOrigin,
  getSituationsActive,
  SearchUser,
  updateUserSpeed,
  createUserSpeedUserSpeed,
  insertHistoricBirth,
  createDemand,
  GetDemanById,
  GetHistoricByDemand,
  updateHistoricDemand,
  createHistoricDemand,
  updateDemandUser,
  GetDemandsPaginate,
  GetHistoricPaginate,
  deleteHistoricPerson,
  createHistoricPerson,
  updateHistoricPerson,
  GetDemandsByProperties,
  getStatusQuantities,
  deleteDemandPerson,
  generatePDF,
  insertHistoricBirthNotSend,
  getNeighborhoods,
};