import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Box,
  Tag,
} from "@chakra-ui/react";
import { AiOutlineWhatsApp, AiOutlinePhone } from "react-icons/ai";
import Card from "components/card/Card.js";
import React, { useState } from "react";
import { mask } from "utils/mask";
import { Item } from "semantic-ui-react";

export default function Default(props) {
  const [expanded, setExpanded] = useState(false);
  const {
    startContent,
    endContent,
    name,
    phoneContact,
    whatsApp,
    phone,
    whatsAppContact,
    whatsAppContactName,
    phoneContactName,
    category,
    description,
    bgColor,
    isUser,
  } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Card py='15px' bgColor={bgColor}>
      <Flex
        my='auto'
        h='100%'
        direction={{ base: 'column', md: 'row' }}
        align={{ base: "center", xl: "start" }}
        justify={{ base: "center", xl: "center" }}>
        {startContent}
        <Stat my='auto' ms={startContent ? "18px" : "0px"}>
          <StatNumber
            color={textColor}
            fontSize={{
              base: "lg",
            }}>
            {name}
          </StatNumber>
          {!isUser && (
            <Flex flexDirection="row" marginTop="4" marginBottom="4">
              <Flex flexDirection="column" marginRight="6">
                {whatsApp && whatsApp.length > 2 ? (
                  <Flex alignItems="center" marginBottom="2">
                    <AiOutlineWhatsApp color="green" style={{ marginRight: 4 }} />
                    <StatLabel
                      as="a"
                      href={`whatsapp://send?phone=${whatsApp}`}
                    >
                      {mask(whatsApp)}
                    </StatLabel>
                  </Flex>
                ) : (
                  <Flex alignItems="center" marginBottom="2">
                   <AiOutlineWhatsApp color="green" style={{ marginRight: 4 }} />
                    <StatLabel as="a">
                      Não informado
                    </StatLabel>
                  </Flex>
                )}
                <Box marginLeft={2} />
                {phone && phone.length > 2 ? (
                  <Flex alignItems="center" marginBottom="2">
                    <AiOutlinePhone color="gray" style={{ marginRight: 4 }} />
                    <StatLabel as="a">
                      {phone}
                    </StatLabel>
                  </Flex>
                ) : (
                  <Flex alignItems="center" marginBottom="2">
                    <AiOutlinePhone color="gray" style={{ marginRight: 4 }} />
                    <StatLabel as="a">
                      Não informado
                    </StatLabel>
                  </Flex>
                )}
              </Flex>
              <Flex direction="column">
                {whatsAppContact && whatsAppContact?.length > 2 ? (
                  <Flex alignItems="center" marginBottom="2">
                    <StatLabel
                      as="a"
                      display="flex"
                      alignItems="center"
                      href={`whatsapp://send?phone=${whatsAppContact}`}
                    >
                      Recado:
                      <AiOutlineWhatsApp color="green" style={{ marginRight: 4, marginLeft: 4 }} />
                      {mask(whatsAppContact)} | {whatsAppContactName}
                    </StatLabel>
                  </Flex>
                ) : (
                  <Flex alignItems="center" marginBottom="2">
                    <StatLabel
                      as="a"
                      display="flex"
                      alignItems="center"
                      href={`whatsapp://send?phone=${whatsAppContact}`}
                    >
                      Recado: Não informado
                    </StatLabel>
                  </Flex>
                )}
                {phoneContact && phoneContact.length > 2 ? (
                  <Flex alignItems="center" marginBottom="2">
                    <StatLabel
                      display="flex"
                      alignItems="center"
                    >
                      Recado:
                      <AiOutlinePhone style={{ marginRight: 4, marginLeft: 4 }} />
                      {phoneContact} | {phoneContactName}
                    </StatLabel>
                  </Flex>
                ) : (
                  <Flex alignItems="center" marginBottom="2">
                    <StatLabel
                      display="flex"
                      alignItems="center"
                    >
                      Recado: Não informado
                    </StatLabel>
                  </Flex>
                )}
              </Flex>
            </Flex>
          )}
          {category && (
            <Flex alignItems="center" marginTop={-1} marginBottom="4">
              <Flex flexWrap="wrap">
                {category?.length > 0 &&
                  category.map((it) => (
                    <Tag
                      padding={2}
                      size='md'
                      marginBottom="1"
                      marginRight={1}
                      colorScheme='orange'
                      borderRadius='md'>
                      {it.name}
                    </Tag>
                  ))}
              </Flex>
            </Flex>
          )}
          <Flex marginBottom="4">
            <Item.Description>
              <div style={{ whiteSpace: "pre-line", marginTop: 2 }}>
                <StatLabel as="span">
                  {expanded && description}
                  {!expanded && description?.substr(0, 300)}{" "}
                  {
                    !expanded &&
                      description?.length > 300 ? (
                      <a style={{ color: "#021d89", fontWeight: "500" }}
                        onClick={() => {
                          setExpanded(true);
                        }}
                      >
                        ver mais...
                      </a>
                    ) : null}
                  {expanded &&
                    description &&
                    description &&
                    description.length > 125 ? (
                    <>
                      <br />
                      <a style={{ color: "#021d89", fontWeight: "500" }}
                        onClick={() => {
                          setExpanded(false);
                        }}>
                        ...ver menos
                      </a>
                    </>
                  ) : null}
                </StatLabel>
              </div>
            </Item.Description>
          </Flex>
        </Stat>
        <Flex ms='auto' w='max-content'>
          {endContent}
        </Flex>
      </Flex>
    </Card>
  );
}
